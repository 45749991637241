/* import tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* import libs */
@import "~nprogress/nprogress.css";
@import "~react-toastify/dist/ReactToastify.css";
/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,500;0,700;1,400&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
body {
  margin: 0;
  font-family:'Poppins', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-nav-active-gradient {
  background: linear-gradient(
    90deg,
    rgba(255, 230, 0, 0.1) -209.67%,
    rgba(9, 193, 83, 0.1) -42.28%,
    rgba(28, 112, 210, 0.1) 33.19%,
    rgba(75, 28, 210, 0.1) 154.48%
  );
}

#nprogress .bar {
  background: #FDA100 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #FDA100, 0 0 5px #FDA100;
}


.border-gradient {
  border-image-source: linear-gradient(
    134.05deg,
    #ffe600 -11.49%,
    #09c153 27.1%,
    #1c70d2 69.85%,
    #4b1cd2 102.51%
  );
  border-image-slice: 1;
}

#nprogress .spinner-icon {
  border-top-color: #FDA100;
  border-left-color: #FDA100;
}


.assessment-card{
  border-radius: 10px;
border: 1px solid linear-gradient(107deg, rgba(255, 230, 0, 0.10) -68.5%, rgba(9, 193, 83, 0.10) 10.02%, rgba(28, 112, 210, 0.10) 45.42%, rgba(75, 28, 210, 0.10) 99.11%);

;
background: linear-gradient(107deg, rgba(255, 230, 0, 0.10) -68.5%, rgba(9, 193, 83, 0.10) 10.02%, rgba(28, 112, 210, 0.10) 45.42%, rgba(75, 28, 210, 0.10) 99.11%);
}


/* nprogress */
#nprogress {
  left: 0;
  top: 0;
  z-index: 9999;
  position: fixed;
  height: 100%;
  width: 100%;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.725);
  display: flex;
  align-items: center;
  justify-content: center;
}
#nprogress .bar {
  top: 0;
  left: 0;
  z-index: 9999;
  position: fixed;
  height: 4px;
  width: 100%;
  background: #29d;
}
#nprogress .spinner {
  display: inline-flex;
  position: fixed;
  top: 50%;
  left: 0vw;
  width: 80px;
  height: 80px;
  margin: auto;
}

#nprogress .spinner .spinner-icon {
  display: block;
  width: 40px;
  height: 40px;
  margin: 8px;
  border-radius: 50%;
  border: 2px solid;
  border-color: #e4e4ed;
  border-right-color: #1700C4;
  animation: lds-dual-ring 0.5s infinite linear;
}


@keyframes lds-dual-ring {
  to {
    transform: rotate(1turn);
  }
}





